<template>
  <div class="Streams is-hidden-mobile">
    <transition-group name="list" tag="div">
      <div
        v-for="mention in mentions"
        :key="mention.id"
        class="Streams__entry m-b-s"
      >
        <StreamsSidebarItem :mention="mention" />
      </div>

      <div
        v-if="mentions.length === 0"
        key="no_mentions"
        class="Streams__noMentions p-a-m has-background-grey-darker has-text-centered has-text-white"
      >
        <div class="is-flex is-aligned-center m-b-s">
          <div class="loader" />
        </div>

        <div v-if="!isEndOfBrandList">
          {{ $t('components.streams.stream_sidebar.fetching_mentions') }}...
        </div>

        <div v-else>
          <span
            class="text"
            v-html="$t('components.streams.stream_sidebar.no_mentions_for_brands')"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import StreamsSidebarItem from '@/components/sidebars/StreamsSidebarItemV2'

export default {
  components: {
    StreamsSidebarItem
  },

  data: () => ({
    mediaNotifications: [],
    fetchedNotifications: [],
    seenIds: {},
    mentions: [],
    timer: null,
    speed: 1000 * 60 * 10, // 10 min,
    maxNumberOfMentions: 15
  }),

  computed: {
    isEndOfBrandList () {
      return this.fetchedNotifications.length >= this.mediaNotifications.length
    }
  },

  async created () {
    try {
      await this.fetchMediaNotifications()

      this.startFetchingMentions()
    } catch (e) {
      console.log(e)
    }
  },

  beforeDestroy () {
    this.stop()
  },

  methods: {
    stop () {
      clearTimeout(this.timer)
    },

    async fetchMediaNotifications () {
      await this.$api.get('/user/media-notifications').then(response => {
        this.mediaNotifications = response.data.data.map(notification => notification.id)
      })
    },

    startFetchingMentions () {
      if (this.isEndOfBrandList) {
        return
      }

      this.stop()

      this.fetchMentions()

      this.setFetchMentionsLoop()
    },

    getNextNotificationId () {
      const notifications = this.mediaNotifications

      const currentNotification = this.fetchedNotifications.length > 0 ? this.fetchedNotifications.length - 1 : this.fetchedNotifications.length

      return notifications[currentNotification]
    },

    fetchMentions () {
      // extra check because Chrome bugs and does not destroy the timeout
      if (this._isDestroyed || this._isBeingDestroyed) {
        return
      }

      // Reset just in case
      this.stop()

      // Start over again if we are at the end
      if (this.isEndOfBrandList) {
        // reset the counter
        this.fetchedNotifications = []

        // take a rest ;)
        return this.setFetchMentionsLoop()
      }

      const mediaNotificationUuid = this.getNextNotificationId()

      // get the current brand
      const activeBrand = this.$store.getters.activeBrand

      if (!activeBrand) {
        // Most probably a bug, because this should never happen.
        return this.handleNoCurrentBrand()
      }

      this.fetchedNotifications.push(mediaNotificationUuid)

      this.fetchMentionsFromApi(mediaNotificationUuid, activeBrand)
    },

    setFetchMentionsLoop () {
      this.timer = setTimeout(() => {
        this.fetchMentions()
      }, this.speed)
    },

    /**
     * Fetches the mentions for a brand
     * @param {String} mediaNotificationUuid
     * @param {Object} brand
     * @return {*}
     */
    fetchMentionsFromApi (mediaNotificationId, brand) {
      return this.$api.getData(`/user/media-notifications/${mediaNotificationId}/feed?brandId=${brand.id}`)
        .then(response => this.handleResponse(response, brand))
        .catch(() => {
          // There was an error, so we are fetching for the next right away
          this.setFetchMentionsLoop()
        })
    },

    handleResponse (mentions, brand) {
      const newMentions = mentions.filter(mention => (this.seenIds[mention.id] === undefined))

      // if there are no new mentions, reset the interval and fetch for next brand
      if (newMentions.length === 0) {
        return this.setFetchMentionsLoop()
      }

      newMentions.forEach(mention => {
        this.seenIds[mention.id] = true
        // // add the brand to the mention
        // mention.brand = {
        //   name: brand.name,
        //   logo: brand.logo.cropped || brand.logo.original,
        //   country: this.$safeGet(brand, 'country.data')
        // }
      })

      // step 1: insert new mentions as a staggered rate
      this.staggerMentions(newMentions)

      // start another staggered fetch after this one
      this.setFetchMentionsLoop()
    },

    staggerMentions (newMentions) {
      // seep in the response information bit by bit
      // step 1. determine spread
      const interval = this.speed / (newMentions.length || 1)

      newMentions.reverse().forEach((mention, index) => {
        setTimeout(() => {
          this.mentions.unshift(mention)

          if (this.mentions.length > this.maxNumberOfMentions) {
            this.mentions.pop()
          }
        }, index * interval)
      })
    },

    handleNoCurrentBrand () {
      this.stop()

      // reset the counter
      this.fetchedNotifications = []
    }
  }
}
</script>

<style lang="scss">
@import "~utils";
</style>
