<template>
  <a
    :href="mention.source_link"
    target="_blank"
    @click="trackViewSidebarMention"
  >
    <background-image
      :src="thumbnailCoverImage"
      class="StreamsSidebarItem has-background-grey-darker is-column"
      shadow
    >
      <div class="p-a-s is-expanded is-column">
        <div class="columns is-mobile is-expanded">
          <div class="column is-column">
            <!-- <div class="StreamsSidebarItem_brand m-b-a">
              <span class="has-text-weight-semibold is-uppercase tracking-wider has-background-grey-darker p-h-s"> {{ mention.brand.name }}</span>
            </div> -->
            <div v-if="isAlternativeView" class="m-b-xs p-l-s">
              <span v-if="mention.published_at" class="is-italic has-text-weight-semibold">
                {{ publicationDate(mention.published_at) }} -
              </span>
              <p class="StreamsSidebarItem__body break-words is-inline">
                {{ truncate(mention.content_body, 80) }}
              </p>
            </div>
            <div v-else-if="mention.content_title" class="StreamsSidebarItem_title has-text-weight-bold is-size-5 p-l-s">
              {{ truncate(mention.content_title, 30) }}
            </div>
            <div v-if="mention.media_title_name" class="StreamsSidebarItem_outlet">
              <span class="has-background-black p-h-s">
                {{ mention.media_title_name }}
              </span>
            </div>
          </div>
          <div v-if="isAlternativeView" class="column is-one-third">
            <background-image :src="mention.thumbnail" class="is-full-height" />
          </div>
        </div>
      </div>
      <div
        class="StreamsSidebarItem_meta is-flex can-wrap is-aligned-middle has-background-black border-t-grey-lighter p-v-xs p-h-m has-text-weight-semibold is-size-sm"
      >
        <div class="StreamsSidebarItem_language is-flex is-aligned-middle m-r-s">
          <div class="icon">
            <v-icon type="globe" />
          </div>
          <span>{{ mention.language }}</span>
        </div>
        <div class="StreamsSidebarItem_mediaType is-flex is-aligned-middle m-r-s">
          <div class="icon">
            <clipping-type-icon :type="mention.type" />
          </div>
          <div>{{ $t(mediaTypeObject.label) }}</div>
        </div>
        <div class="StreamsSidebarItem_impressions is-flex is-aligned-middle m-r-s has-nowrap">
          <div class="icon">
            <v-icon type="eye" />
          </div>
          {{ formatImpressionsValue(mention.impressions) }}
        </div>
        <div v-if="sentiment" class="StreamsSidebarItem_reputation is-flex is-aligned-middle m-r-s">
          <div class="icon">
            <v-icon :type="sentiment.icon_full" />
          </div>
          {{ $t(sentiment.label) }}
        </div>
      </div>
    </background-image>
  </a>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'

export default {
  name: 'StreamsSidebarItem',

  mixins: [FormatImpressionsValueMixin, TruncateMixin, PublicationDateMixin],

  props: {
    /** @type HF_Mention */
    mention: {
      type: Object,
      required: true
    }
  },
  computed: {
    mediaTypeObject () {
      return MEDIA_TYPES[this.mention.type]
    },
    thumbnailCoverImage () {
      return !this.isAlternativeView ? this.mention.thumbnail : ''
    },
    sentiment () {
      return SENTIMENT_TYPES[this.mention.sentiment]
    },
    isAlternativeView () {
      return this.mention.type !== 'web'
    }
  },
  methods: {
    trackViewSidebarMention () {
      this.$ma.trackEvent({
        action: 'View Sidebar Mention',
        properties: {
          mentionId: this.mention.id,
          mentionMediaType: this.mention.type,
          outletName: this.mention.media_title_name,
          outletId: this.mention.media_title_id
        }
      })
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StreamsSidebarItem {
  min-height: 200px;
}
</style>
